'use strict';

const TEMPLATES_DIR = 'app-partner/templates/';

angular.module('managerApp.partner', ['ngRoute']).config(($routeProvider) => {
    $routeProvider
            .when('/partner/mon-compte', {
                templateUrl: TEMPLATES_DIR + 'account.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'account'
                }
            })
            .when('/partner/mon-compte-prepaye', {
                templateUrl: TEMPLATES_DIR + 'prepaid.account.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'prepaid-account'
                }
            })
            
            .when('/partner/faire-une-demarche', {
                templateUrl: TEMPLATES_DIR + 'create.procedure.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'create-procedure'
                }
            })
            
            .when('/partner/messagerie', {
                templateUrl: TEMPLATES_DIR + 'chat.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'chat'
                }
            })             
            
            .when('/partner/mes-bornes', {
                templateUrl: TEMPLATES_DIR + 'terminals.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'terminals'
                }
            })
            .when('/partner/mes-tablettes', {
                templateUrl: TEMPLATES_DIR + 'tablets.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'tablets'
                }
            })
            .when('/partner/les-gestionnaires-des-appareils', {
                templateUrl: TEMPLATES_DIR + 'devices.managers.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'devices-managers'
                }
            })
            .when('/partner/demandes-paiement-en-especes', {
                templateUrl: TEMPLATES_DIR + 'payment.requests.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'payment-requests'
                }
            })
            .when('/partner/mon-tableau-de-bord', {
                templateUrl: TEMPLATES_DIR + 'dashboard.html',
                controller: 'PartnerController',
                resolve: {
                    _templatesDir: () => TEMPLATES_DIR,
                    _type: () => 'dashboard'
                }
            });

});